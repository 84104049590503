// extracted by mini-css-extract-plugin
export var careers = "PageDefault-module--careers--40139";
export var colorGreen = "PageDefault-module--color-green--95457";
export var colorGreenLight = "PageDefault-module--color-green-light--b5b91";
export var colorOrange = "PageDefault-module--color-orange--ef405";
export var colorPink = "PageDefault-module--color-pink--3d0d9";
export var colorPurple = "PageDefault-module--color-purple--63294";
export var core = "PageDefault-module--core--5318b";
export var fontCustom = "PageDefault-module--font-custom--b533a";
export var functionalSkills = "PageDefault-module--functional-skills--25715";
export var green = "PageDefault-module--green--a45a4";
export var greenLight = "PageDefault-module--green-light--5a528";
export var imgText = "PageDefault-module--img-text--91d31";
export var imgWhy = "PageDefault-module--img-why--f8c06";
export var largeFont = "PageDefault-module--large-font--d3448";
export var largeVersion = "PageDefault-module--large-version--90286";
export var littleBox = "PageDefault-module--little-box--4b697";
export var littleFont = "PageDefault-module--little-font--e2f8e";
export var margin = "PageDefault-module--margin--bd622";
export var marginTop = "PageDefault-module--marginTop--8d7d3";
export var notFound = "PageDefault-module--not-found--7969d";
export var oneBox = "PageDefault-module--one-box--d65cf";
export var orange = "PageDefault-module--orange--b399d";
export var paddingTop = "PageDefault-module--paddingTop--7cf25";
export var paddingX = "PageDefault-module--padding-x--baedb";
export var purple = "PageDefault-module--purple--c9d1e";
export var round = "PageDefault-module--round--4e431";
export var skillsBox = "PageDefault-module--skillsBox--3c99e";
export var structureBox = "PageDefault-module--structure-box--9e82a";
export var supportBox = "PageDefault-module--support-box--1e6b9";
export var twoBox = "PageDefault-module--two-box--9f6af";
export var widthCustom = "PageDefault-module--width-custom--82329";